import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  FC,
  useMemo,
  useContext,
} from 'react'
import { formatDateObjToYearMonth, getDefaultStartEndDate } from '../utils'

type MonthPickerContextValue = {
  startYearMonth: string
  endYearMonth: string
  setStartYearMonth: Dispatch<SetStateAction<string>>
  setEndYearMonth: Dispatch<SetStateAction<string>>
}

const { startDate, endDate } = getDefaultStartEndDate()

export const MonthPickerContext = createContext<MonthPickerContextValue>({
  startYearMonth: formatDateObjToYearMonth(startDate),
  endYearMonth: formatDateObjToYearMonth(endDate),
  setStartYearMonth: () => {
    throw new Error(
      'attempt to set startYearMonth without initializing setter, please initialize MonthPickerContext with MonthPickerProvider'
    )
  },
  setEndYearMonth: () => {
    throw new Error(
      'attempt to set endYearMonth without initializing setter, please initialize MonthPickerContext with MonthPickerProvider'
    )
  },
})

export const MonthPickerProvider: FC = ({ children }) => {
  const [startYearMonth, setStartYearMonth] = useState(
    formatDateObjToYearMonth(startDate)
  )
  const [endYearMonth, setEndYearMonth] = useState(
    formatDateObjToYearMonth(endDate)
  )

  const contextValue = useMemo(
    () => ({
      startYearMonth,
      endYearMonth,
      setStartYearMonth,
      setEndYearMonth,
    }),
    [startYearMonth, endYearMonth, setStartYearMonth, setEndYearMonth]
  )

  return (
    <MonthPickerContext.Provider value={contextValue}>
      {children}
    </MonthPickerContext.Provider>
  )
}

export const useMonthPickerContext = () => {
  const context = useContext(MonthPickerContext)
  if (!context) {
    throw new Error(
      'useMonthPickerContext must be used within a MonthPickerProvider'
    )
  }
  return context
}
